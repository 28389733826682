import Vue from 'vue'

export async function logoutFromApp () {
  await Vue.$cookies.remove('token')
  localStorage.removeItem('users')
  window.location.href = Vue.baseUrl
}

export async function logoutFromBackend () {
  const response = await Vue.prototype.$http.get(`logout`)
  return response.data
}

export async function userDelete (id) {
  const response = await Vue.prototype.$http.delete(`/sign-up/${id}`)
  return response.data
}
