<template>
  <div id="app">
    <router-view/>
    <loading-overlay></loading-overlay>
  </div>
</template>
<script>
  import { logoutFromApp } from '@/services/auth'
  import LoadingOverlay from './components/LoadingOverlay'

  export default {
    name: 'App',
    components: { LoadingOverlay },
    mounted () {
      this.renderCustomJs();
    },
    created: function () {
      this.$http.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401 && error.response.data.error === 'Unauthorised') {
          logoutFromApp()
        }
        return Promise.reject(error)
      })
    },
    methods: {
      renderCustomJs () {
        let customJavaScript = document.createElement('script');
        customJavaScript.async = true;
        customJavaScript.setAttribute('src', this.baseUrl + 'assets/js/custom.js');
        document.head.appendChild(customJavaScript);
      }
    }
  }
</script>

<style>
  ._vue-flash-msg-body {
    z-index: 1099 !important;
  }

  #leftNav a.router-link-exact-active {
    background-color: #20c997;
    color: white;
  }
</style>
