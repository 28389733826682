<template>
    <div class="footer">
        <p class="text-center pt-1 mb-1">&copy; Copyright 2016 - {{ new Date().getFullYear() }} <a href="http://twinsoftbd.com" target="_blank">Twinsoft Solution Bangladesh</a>. All rights reserved. <span class="text-success">V-C4.2</span></p>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        components: {},
        data() {
            return {
                configData: JSON.parse(localStorage.getItem("users"))
            }
        },
    }
</script>
