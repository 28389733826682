import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import moment from 'moment'
import '@desislavsd/vue-select/dist/vue-select.css'
import VueSelect from '@desislavsd/vue-select'
import Datepicker from 'vuejs-datepicker'
import FlashMessage from '@smartweb/vue-flash-message'
import 'bootstrap'
import VueCookies from 'vue-cookies'
import MarqueeText from 'vue-marquee-text-component'
import NProgress from 'vue-nprogress'

Vue.use(VueCookies);
Vue.component('marquee-text', MarqueeText);
Vue.use(FlashMessage);
Vue.component('datepicker', Datepicker);
// registers <v-select /> globally
Vue.use(VueSelect, {
  name: 'vSelect',
  mixin: {}
});

const NProgressOptions = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, NProgressOptions);
const nprogress = new NProgress();

Vue.use({
  install: function (Vue, options) {
    options;
    Vue.prototype.$jQuery = require('jquery'); // you'll have this.$jQuery anywhere in your vue project
  }
});

Vue.component('pagination', require('laravel-vue-pagination'));

if (!window.$cookies.isKey('uuid')) {
  const uuid = Math.random().toString().replace('0.', '') + btoa(Math.random()).substring(0, 32);
  window.$cookies.set('uuid', uuid, 1800);//uuid will expired after (1800 second)30min.
}
const baseLogin = axios.create({
  baseURL: process.env.VUE_APP_APIURL,
  headers: {"SessionUuid": window.$cookies.get('uuid')}
});
Vue.prototype.$httpLogin = baseLogin;

let admin_id = JSON.parse(localStorage.getItem("users"))?.adminId;
const base = axios.create({
  baseURL: process.env.VUE_APP_APIURL,
  headers: {
    "Authorization": `Bearer ${window.$cookies.get("token")}`,
    "LoggedAdminId": admin_id
  }
});

base.interceptors.response.use(response => {
  if (!window.$cookies.isKey("token")) {
    alert("You are logged out. Please login to continue.");
    window.location.href = process.env.VUE_APP_BASEURL;
  } else {
    return response;
  }
});

Vue.prototype.$http = base;

Vue.config.productionTip = false;

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
});

Vue.filter('enToBn', function (values) {
  let numbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  let output = [];
  if (values) {
    for (let i = 0; i < values.length; ++i) {
      if (numbers.hasOwnProperty(values[i])) {
        output.push(numbers[values[i]]);
      } else {
        output.push(values[i]);
      }
    }
    return output.join('');
  }
});

Vue.filter('translateToBangla', function (value) {
  let numbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  let output = [];
  if (value) {
    let values = parseFloat(value).toFixed();
    for (let i = 0; i < values.length; ++i) {
      if (numbers.hasOwnProperty(values[i])) {
        output.push(numbers[values[i]]);
      } else {
        output.push(values[i]);
      }
    }
    return output.join('');
  }
});

Vue.filter('transactionConvertToBangla', function (value) {
  let numbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  let output = [];
  if (value) {
    let values = parseFloat(value).toLocaleString('bn-BD', {minimumFractionDigits: 2});
    for (let i = 0; i < values.length; ++i) {
      if (numbers.hasOwnProperty(values[i])) {
        output.push(numbers[values[i]]);
      } else {
        output.push(values[i]);
      }
    }
    return output.join('');
  } else {
    return '০.০০';
  }
});

Vue.filter('monthEnToBn', function (value) {
  let numbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  let enMonths = ["", "জানুয়ারি", "ফেব্রুয়ারী", "মার্চ", "এপ্রিল", "মে", "জুন", "জুলাই", "আগস্ট", "সেপ্টেম্বর", "অক্টোবর", "নভেম্বর", "ডিসেম্বর"];
  let valuesArray = value.split(',');
  let output = [];
  if (value) {
    let monthName = enMonths[valuesArray[0]];
    value = valuesArray[1];
    for (let i = 0; i < value.length; ++i) {
      if (numbers.hasOwnProperty(value[i])) {
        output.push(numbers[value[i]]);
      } else {
        output.push(value[i]);
      }
    }
    return monthName + ' - ' + output.join('');
  }
});

Vue.APIURL = Vue.prototype.APIURL = process.env.VUE_APP_APIURL;
Vue.IMAGEURL = Vue.prototype.IMAGEURL = process.env.VUE_APP_IMAGEURL;
Vue.baseUrl = Vue.prototype.baseUrl = process.env.VUE_APP_BASEURL;
Vue.SECRET = Vue.prototype.SECRET = process.env.VUE_APP_SECRET;

new Vue({
  router,
  nprogress,
  render: h => h(App)
}).$mount('#app');
