<template>
    <div id="auth">
        <transition name="fade">
            <router-view/>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'AuthLayout'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
