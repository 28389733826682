<template>
    <div id="dashboard">
        <nprogress-container></nprogress-container>
        <Menu></Menu>
        <FlashMessage :position="'right top'"></FlashMessage>
        <div class="container-fluid app-body">
            <!-- Page content body -->
            <div class="card-body rounded-0 border">
                <transition name="fade">
                    <router-view/>
                </transition>
            </div>
            <!-- Page content body -->
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
    import Menu from "../views/common/Menu";
    import Footer from "../views/common/Footer";

    export default {
        name: 'DashboardLayout',
        components: {
            Footer,
            Menu,
            NprogressContainer,
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .app-body {
        padding: 2px !important;
    }

    ._vue-flash-msg-body {
        z-index: 1 !important;
    }

    .v-select {
        --height: 31px;
    }
</style>
